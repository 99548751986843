import { LOADING_CAPTURES_METADATA, STORE_CAPTURES_METADATA } from "./actionTypes";

const initialCapturesMetadataState = { data: [], isFetching: false };

export const captureMetadata = (state = initialCapturesMetadataState, action) => {
  switch (action.type) {
    case LOADING_CAPTURES_METADATA:
      return { data: [], isFetching: true };
    case STORE_CAPTURES_METADATA:
      return { data: action.metadata, isFetching: false };
    default:
      return state;
  }
};

export default captureMetadata;
