import { combineReducers } from "redux";
import {
  LOADING_TEAM_INFO,
  STORE_TEAM_INFO,
  LOADING_ACCOUNT_API_KEYS,
  STORE_ACCOUNT_API_KEYS,
} from "./actionTypes";

const initialTeamInfoState = { data: {}, isFetching: false };
const initialAccountApiKeysState = { data: [], isFetching: false };

export const teamInfo = (state = initialTeamInfoState, action) => {
  switch (action.type) {
    case LOADING_TEAM_INFO:
      return { data: [], isFetching: true };
    case STORE_TEAM_INFO:
      return { data: action.payload, isFetching: false };
    default:
      return state;
  }
};

export const accountApiKeys = (state = initialAccountApiKeysState, action) => {
  switch (action.type) {
    case LOADING_ACCOUNT_API_KEYS:
      return { data: [], isFetching: true };
    case STORE_ACCOUNT_API_KEYS:
      return { data: action.payload, isFetching: false };
    default:
      return state;
  }
};

export default combineReducers({ teamInfo, accountApiKeys });
