export default (theme) => ({
  dialogFormContainer: {
    padding: theme.spacing(2),
    width: "750px",
    // minHeight: "60vh",
    margin: "auto",
    alignItems: "center",
    "@media (max-width: 1300px)": {
      width: "auto",
    },
  },
  dialogFormWrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "50vh",
    justifyContent: "space-between",
  },
});
