export const LOADING_PIPELINES = "LOADING_PIPELINES";
export const LOADING_PIPELINES_RESULTS = "LOADING_PIPELINES_RESULTS";
export const LOADING_PIPELINE_SEEDS = "LOADING_PIPELINE_SEEDS";

export const LOADING_PIPELINE_DATA = "LOADING_PIPELINE_DATA";
export const STORE_PIPELINE_DATA = "STORE_PIPELINE_DATA";

export const ADD_PIPELINE = "ADD_PIPELINE";
export const UPDATED_PIPELINE = "UPDATED_PIPELINE";
export const UPDATING_PIPELINE = "UPDATING_PIPELINE";
export const DELETING_PIPELINE = "DELETING_PIPELINE";

export const STORE_PIPELINE_SEEDS = "STORE_PIPELINE_SEEDS";
export const STORE_PIPELINES = "STORE_PIPELINES";
export const STORE_SELECTED_PIPELINE = "STORE_SELECTED_PIPELINE";
export const STORE_SELECTED_PIPELINE_NAME = "STORE_SELECTED_PIPELINE_NAME";
export const STORE_PIPELINE_RESULTS = "STORE_PIPELINE_RESULTS";
export const CLEAR_PIPELINE_RESULTS = "CLEAR_PIPELINE_RESULTS";

export const SUBMITING_OPTIMIZATION_REQUEST = "SUBMITING_OPTIMIZATION_REQUEST";
export const SUBMITED_OPTIMIZATION_REQUEST = "SUBMITED_OPTIMIZATION_REQUEST";
export const FINISHED_OPTIMIZATION = "FINISHED_OPTIMIZATION";
export const RUNNING_OPTIMIZATION = "RUNNING_OPTIMIZATION";
export const FAILED_OPTIMIZATION = "FAILED_OPTIMIZATION";

export const KILLING_OPTIMIZATION_REQUEST = "KILLING_OPTIMIZATION_REQUEST";
export const KILLING_OPTIMIZATION_FAILED = "KILLING_OPTIMIZATION_FAILED";
export const OPTIMIZATION_REQUEST_KILLED = "OPTIMIZATION_REQUEST_KILLED";
export const OPTIMIZATION_REQUEST_NOT_STARTED = "OPTIMIZATION_REQUEST_NOT_STARTED";
export const CLEAR_OPTIMIZATION_REQUEST_STATUS = "CLEAR_OPTIMIZATION_REQUEST_STATUS";

export const PIPELINE_DELETED = "PIPELINE_DELETED";

export const UPDATE_OPTIMIATION_LOGS = "UPDATE_OPTIMIATION_LOGS";
export const CLEAR_OPTIMIATION_LOGS = "CLEAR_OPTIMIATION_LOGS";

export const UPDATE_OPTIMIATION_DETAILED_LOGS = "UPDATE_OPTIMIATION_DETAILED_LOGS";
export const CLEAR_OPTIMIATION_DETAILED_LOGS = "CLEAR_OPTIMIATION_DETAILED_LOGS";

export const LOAD_ITERATION_METRICS = "LOAD_ITERATION_METRICS";
export const UPDATE_ITERATION_METRICS = "UPDATE_ITERATION_METRICS";
export const CLEAR_ITERATION_METRICS = "CLEAR_ITERATION_METRICS";

export const STORE_PIPELINE_STATUS = "STORE_PIPELINE_STATUS";
export const STORE_PIPELINE_EXEC_TYPE = "STORE_PIPELINE_EXEC_TYPE";
export const CLEAR_PIPELINE_EXEC_TYPE = "CLEAR_PIPELINE_EXEC_TYPE";
