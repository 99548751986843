import helper from "store/helper";
import logger from "store/logger";

import { CLEAR_QUERY_CACHE_STATUS } from "../actionTypes";

export const clearQueryCacheStatus = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_QUERY_CACHE_STATUS });
  } catch (err) {
    logger.logError("", `${helper.getResponseErrorDetails(err)}`, err, "clearQueryCacheStatus");
  }
};

export default clearQueryCacheStatus;
