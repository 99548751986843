import { combineReducers } from "redux";
import { STORE_PLOTS, STORE_PLOT } from "./actionTypes";

const initialPlotState = [];
export function plotsData(state = initialPlotState, action) {
  switch (action.type) {
    case STORE_PLOTS:
      return action.plots;
    default:
      return state;
  }
}

const initialSelectedPlotState = "segment";
export function selectedPlot(state = initialSelectedPlotState, action) {
  switch (action.type) {
    case STORE_PLOT:
      return action.plotId;
    default:
      return state;
  }
}

export default combineReducers({
  selectedPlot,
  plotsData,
});
