const WIDTH_FOR_SHORT_TEXT = 1367;
const WIDTH_FOR_TABLET_TEXT = 900;
const WIDTH_FOR_PHONE_TEXT = 550;
const TRUNCATE_NAME_OVER_SHORT_TEXT = 40;
const TRUNCATE_NAME_OVER_TABLET_TEXT = 35;
const TRUNCATE_NAME_OVER_PHONE_TEXT = 19;
const TRUNCATE_NAME_OVER = 50;

export default {
  WIDTH_FOR_SHORT_TEXT,
  TRUNCATE_NAME_OVER_SHORT_TEXT,
  TRUNCATE_NAME_OVER,
  WIDTH_FOR_PHONE_TEXT,
  TRUNCATE_NAME_OVER_PHONE_TEXT,
  TRUNCATE_NAME_OVER_TABLET_TEXT,
  WIDTH_FOR_TABLET_TEXT,
};
