import { STORE_PLATFORMS, LOADING_PLATFORMS } from "./actionTypes";

const initialPlatformsState = { data: [], isFetching: false };
export function platforms(state = initialPlatformsState, action) {
  switch (action.type) {
    case LOADING_PLATFORMS:
      return { data: [], isFetching: true };
    case STORE_PLATFORMS:
      return { data: action.platforms, isFetching: false };
    default:
      return state;
  }
}

export default platforms;
