import api from "../api";
import logger from "../logger";
import helper from "../helper";
import { STORE_CAPTURE_CONFIGURATIONS, LOADING_CAPTURE_CONFIGURATIONS } from "./actionTypes";

export const loadCaptureConfigurations = (projectId) => async (dispatch) => {
  dispatch({ type: LOADING_CAPTURE_CONFIGURATIONS });
  let captureConfigurations = [];
  if (!helper.isNullOrEmpty(projectId)) {
    try {
      const { data: responseBody } = await api.get(`/project/${projectId}/captureconfiguration/`);
      captureConfigurations = responseBody;
    } catch (err) {
      logger.logError(
        "",
        `${helper.getResponseErrorDetails(err)} \n--projectId:${projectId}`,
        err,
        "loadCaptureConfigurations",
      );
    }
  }
  dispatch({
    type: STORE_CAPTURE_CONFIGURATIONS,
    captureConfigurations,
  });
};
