import { setSelectedPipeline } from "store/pipelines/actions";
import { clearModel, setSelectedModel } from "store/models/actions";

const clearProjectSelectedData = () => async (dispatch) => {
  dispatch(setSelectedPipeline(""));
  dispatch(setSelectedModel(""));
  dispatch(clearModel());
};

export default clearProjectSelectedData;
