export const DELETED_PROJECT = "DELETED_PROJECT";
export const DELETING_PROJECT = "DELETING_PROJECT";
export const STORE_PROJECTS = "STORE_PROJECTS";
export const STORE_SELECTED_PROJECT = "STORE_SELECTED_PROJECT";
export const STORE_LAST_SELECTED_PROJECT = "STORE_LAST_SELECTED_PROJECT";

export const LOADING_PROJECTS = "LOADING_PROJECTS";
export const LOADING_PROJECT_STATISTICS = "LOADING_PROJECT_STATISTICS";
export const STORE_PROJECT_STATISTICS = "STORE_PROJECT_STATISTICS";

export const OPTIMIZED_PROJECT = "OPTIMIZED_PROJECT";
export const UPDATING_PROJECT = "UPDATING_PROJECT";
export const UPDATED_PROJECT = "UPDATED_PROJECT";
export const DIRTY_PROJECT = "DIRTY_PROJECT";
