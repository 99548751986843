export const CACHE_BUILD_STATUSES = {
  SUCCESS: "SUCCESS",
  BUILDING: "BUILDING",
  FAILURE: "FAILURE",
  NOT_BUILT: "NOT_BUILT",
};

export const CACHE_STATUSES = {
  CACHED: "CACHED",
  BUILDING: "BUILDING",
  FAILED: "FAILED",
  NOT_BUILT: "NOT_BUILT",
};
