import api from "../api";
import logger from "../logger";
import helper from "../helper";
import { STORE_SESSIONS, LOADING_SESSIONS, SET_SELECTED_SESSION } from "./actionTypes";

export const loadSessions = (projectId) => async (dispatch) => {
  dispatch({ type: LOADING_SESSIONS });
  let sessions = [];
  if (!helper.isNullOrEmpty(projectId)) {
    try {
      const { data: responseBody } = await api.get(`/project/${projectId}/segmenter/`);
      sessions = responseBody;
    } catch (err) {
      logger.logError(
        "",
        `${helper.getResponseErrorDetails(err)}\n--projectId:${projectId}`,
        err,
        "loadSessions",
      );
    }
  }
  dispatch({ type: STORE_SESSIONS, sessions });
};

export const createSession = (projectId) => async (dispatch) => {
  dispatch({ type: LOADING_SESSIONS });
  if (!helper.isNullOrEmpty(projectId)) {
    try {
      await api.post(`/project/${projectId}/segmenter/`, {
        name: "Training Session",
        custom: true,
      });
      dispatch(loadSessions(projectId));
    } catch (err) {
      logger.logError(
        "",
        `${helper.getResponseErrorDetails(err)}\n--projectId:${projectId}`,
        err,
        "createSession",
      );
    }
  }
};

export const setSelectedSession = (selectedSessionUUID) => {
  return { type: SET_SELECTED_SESSION, selectedSessionUUID };
};
