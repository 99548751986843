import api from "../api";
import logger from "../logger";
import helper from "../helper";
import { STORE_TRANSFORMS, LOADING_TRANSFORMS } from "./actionTypes";

export const loadTransforms = () => async (dispatch) => {
  dispatch({ type: LOADING_TRANSFORMS });
  let transforms = [];
  try {
    const { data: responseBody } = await api.get("/transform/");
    transforms = responseBody;
  } catch (err) {
    transforms = [];
    logger.logError("", helper.getResponseErrorDetails(err), err, "loadTransforms");
  }
  dispatch({ type: STORE_TRANSFORMS, transforms });
};
