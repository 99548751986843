export const DEBUG = 10;
export const INFO = 20;
export const SUCCESS = 30;
export const WARNING = 40;
export const ERROR = 50;

export const STATUSES = {
  DEBUG,
  INFO,
  SUCCESS,
  WARNING,
  ERROR,
};

export const RUNNING = "RUNNING";
export const KILLING = "KILLING";
export const KILLING_ABORTED = "KILLING_ABORTED";
export const KILLED = "KILLED";
export const COMPLETED = "COMPLETED";
export const FAILED = "FAILED";
export const NOT_STARTED = "NOT_STARTED";

export const RUNNING_STATUSES = {
  RUNNING,
  KILLING,
  KILLING_ABORTED,
  KILLED,
  FAILED,
  COMPLETED,
  NOT_STARTED,
};
