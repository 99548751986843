import makeStyles from "@mui/styles/makeStyles";

const useStyles = () =>
  makeStyles((theme) => ({
    cardTitle: {
      lineHeight: theme.spacing(3),
      fontSize: theme.spacing(3),
      fontWeight: 500,
    },
    cardSubtitle: {
      lineHeight: theme.spacing(3),
      fontSize: theme.spacing(2.5),
      fontWeight: 500,
    },
    cartItemKey: {
      flex: 1,
      fontWeight: 500,
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(2.5),
      color: theme.palette.text.secondary,
    },
    cartItemValue: {
      flex: 1,
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(2.5),
    },
    cardActions: {
      justifyContent: "flex-start",
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    },
  }))();

export default useStyles;
