const TOKEN_KEY = "jwtToken";
const REFRESH_TOKEN_KEY = "jwtRefreshToken";
/*
 * Manage the flow of how Access Tokens are being stored, removed, and retrieved from storage.
 *
 * Current implementation stores to sessionStorage. Local Storage should always be
 * accessed through this instace.
 */
const TokenStorage = {
  isAuthenticated() {
    return Boolean(sessionStorage.getItem(TOKEN_KEY));
  },

  getToken() {
    return sessionStorage.getItem(TOKEN_KEY);
  },

  async saveToken(accessToken) {
    sessionStorage.setItem(TOKEN_KEY, accessToken);
    await Promise.resolve();
  },

  removeToken() {
    sessionStorage.removeItem(TOKEN_KEY);
  },

  getRefreshToken() {
    return sessionStorage.getItem(REFRESH_TOKEN_KEY);
  },

  saveRefreshToken(refreshToken) {
    sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  removeRefreshToken() {
    sessionStorage.removeItem(REFRESH_TOKEN_KEY);
  },
};

export default TokenStorage;
