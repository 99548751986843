export const LOADING_MODELS = "LOADING_MODELS";
export const LOADING_MODEL = "LOADING_MODEL";

export const STORE_MODELS = "STORE_MODELS";
export const STORE_MODEL = "STORE_MODEL";
export const DELETE_MODEL = "DELETE_MODEL";
export const CLEAR_MODEL = "CLEAR_MODEL";

export const STORE_SELECTED_MODEL = "STORE_SELECTED_MODEL";
export const STARTING_MODEL_DOWNLOAD = "STARTING_MODEL_DOWNLOAD";
export const STARTED_MODEL_DOWNLOAD = "STARTED_MODEL_DOWNLOAD";
export const DOWNLOADING_MODEL = "DOWNLOADING_MODEL";
export const MODEL_DOWNLOAD_FAILED = "MODEL_DOWNLOAD_FAILED";
export const MODEL_DOWNLOADED = "MODEL_DOWNLOADED";
export const CANCEL_MODEL_DOWNLOAD = "CANCEL_MODEL_DOWNLOAD";
export const RENAMING_MODEL = "RENAMING_MODEL";
export const RENAMED_MODEL = "RENAMED_MODEL";

export const MODEL_FEATURE_VECTORS_LOADING = "MODEL_FEATURE_VECTOR_LOADING";
export const MODEL_FEATURE_VECTORS_STORE = "MODEL_FEATURE_VECTORS_STORE";
