import { connect } from "react-redux";
import TheAccountInfo from "./TheAccountInfo";

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.userId,
    teamName: state?.auth?.teamInfo?.team || "",
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TheAccountInfo);
