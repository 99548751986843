import {
  STORE_LABELS,
  LOADING_LABELS,
  SET_SELECTED_LABEL,
  CLEAR_SELECTED_LABEL,
} from "./actionTypes";

const initialLabelsState = { data: [], isFetching: false, selectedLabelUUID: "" };
export const labels = (state = initialLabelsState, action) => {
  switch (action.type) {
    case LOADING_LABELS:
      return { ...state, data: [], isFetching: true };
    case STORE_LABELS:
      return { ...state, data: action.labels, isFetching: false };
    case SET_SELECTED_LABEL:
      return { ...state, selectedLabelUUID: action.selectedLabelUUID };
    case CLEAR_SELECTED_LABEL:
      return { ...state, selectedLabelUUID: "" };
    default:
      return state;
  }
};

export default labels;
