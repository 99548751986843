import React from "react";
import { Typography, Dialog, DialogContent, DialogActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import useStyles from "./UIDialogFormMediumStyles";

const UIDialogForm = ({
  title,
  isOpen,
  onClose,
  actionsComponent,
  children,
  isCloseDisabled = false,
  maxWidth = "xs",
  fullWidth = true,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.dialogWrapper}>
        {!isCloseDisabled ? <CloseIcon className={classes.closeIcon} onClick={onClose} /> : null}
        <Typography id="scroll-dialog-title" variant="h2" className={classes.createDialogTitle}>
          {title}
        </Typography>
        {children}
      </DialogContent>
      <DialogActions className={classes.actionWrapper}>{actionsComponent}</DialogActions>
    </Dialog>
  );
};

export default UIDialogForm;
