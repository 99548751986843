export const ASYNC_CECK_INTERVAL = process.env.REACT_APP_ASYNC_CHECK_INTERVAL || 5000;
export const SUPPORT_URL = process.env.REACT_SUPPORT_URL || "https://sensiml.com/support";

// AUTH
export const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID || "";
export const AUTH_CLIENT_SECRET = process.env.REACT_APP_AUTH_CLIENT_SECRET || "";
export const PROMO_LINK = process.env.REACT_APP_PROMO_LINK || "";
export const GETTING_STARTED_GUIDE_LINK = process.env.REACT_APP_GETTING_STARTED_GUIDE_LINK || "";

export const API_URL = process.env.REACT_APP_API_URL || "";

// OAUTH
const {
  REACT_APP_OAUTH_MICROCHIP_CLIENT_ID,
  REACT_APP_OAUTH_MICROCHIP_AUTH_URL,
  REACT_APP_OAUTH_MICROCHIP_REDIRECT_URL,
  REACT_APP_OAUTH_GOOGLE_CLIENT_ID,
  REACT_APP_OAUTH_GOOGLE_AUTH_URL,
  REACT_APP_OAUTH_GOOGLE_REDIRECT_URL,
} = process.env;

export const OAUTH_MICROCHIP_CLIENT_ID = REACT_APP_OAUTH_MICROCHIP_CLIENT_ID || "";
export const OAUTH_MICROCHIP_AUTH_URL = REACT_APP_OAUTH_MICROCHIP_AUTH_URL || "";
export const OAUTH_MICROCHIP_REDIRECT_URL = REACT_APP_OAUTH_MICROCHIP_REDIRECT_URL || "";

export const OAUTH_GOOGLE_CLIENT_ID = REACT_APP_OAUTH_GOOGLE_CLIENT_ID || "";
export const OAUTH_GOOGLE_AUTH_URL = REACT_APP_OAUTH_GOOGLE_AUTH_URL || "";
export const OAUTH_GOOGLE_REDIRECT_URL = REACT_APP_OAUTH_GOOGLE_REDIRECT_URL || "";
export const PLANS_LINK = process.env.REACT_APP_PLANS_LINK || "https://sensiml.com/plans/";
export const CHANGE_PASSWORD_LINK = process.env.REACT_APP_CHANGE_PASSWORD_LINK;
export const SUBSCRIPTION_LINK = process.env.REACT_APP_SUBSCRIPTION_URL;

export const FEATURE_SAMPLE_SIZE_LIMIT = 200;
