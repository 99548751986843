import { connect } from "react-redux";
import { loadTeamInfo } from "store/team/actions";
import { selectTeamUsageStats } from "store/team/selectors";

import TheAccountSubscription from "./TheAccountSubscription";

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.userId,
    teamName: state?.auth?.teamInfo?.team || "",
    subscription: state?.auth?.teamInfo?.subscription || "",
    teamUsageStats: selectTeamUsageStats(state),
    teamUsageStatsIsLoading: state?.team?.teamInfo?.isFetching || false,
  };
};

const mapDispatchToProps = { loadTeamInfo };

export default connect(mapStateToProps, mapDispatchToProps)(TheAccountSubscription);
