import { useState } from "react";

import { RESPONSIVE } from "consts";
import { filterTruncateResponsive } from "filters";

import useWindowResize from "./useWindowResize";

const useFilterTurncateResponsive = () => {
  const [windowSize, setWindowSize] = useState(RESPONSIVE.WIDTH_FOR_SHORT_TEXT);

  useWindowResize((data) => {
    setWindowSize(data.innerWidth);
  });

  const filterText = (text) => filterTruncateResponsive(text, windowSize);

  return filterText;
};

export default useFilterTurncateResponsive;
