import React, { useState } from "react";
import FilterDramaIcon from "@mui/icons-material/FilterDrama";

import ConsoleTabs from "./ConsoleTabs";
import ConsoleTab from "./ConsoleTab";
import ConsoleBody from "./ConsoleBody";

const ConsoleView = ({ viewTabs, children, onChangeTab }) => {
  const [activeMode, setActiveMode] = useState(0);

  const handleChangeActiveMode = (_, newVal) => {
    setActiveMode(newVal);
    onChangeTab(newVal);
  };

  return (
    <>
      <ConsoleTabs
        value={activeMode}
        onChange={handleChangeActiveMode}
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {viewTabs?.length &&
          viewTabs.map((view, index) => (
            <ConsoleTab
              key={`console_tab_${index}`}
              label={view}
              icon={
                <FilterDramaIcon
                  style={{ marginRight: "0.5rem", marginBottom: 0 }}
                  fontSize="small"
                />
              }
            />
          ))}
      </ConsoleTabs>
      <ConsoleBody>{children}</ConsoleBody>
    </>
  );
};

export default ConsoleView;
