import { STORE_DOWNLOAD_MODEL_FORM_DATA } from "./actionTypes";

/*
  initialDownloadFormState
  projectId: key for multi projects store
  isLoaded: false,
  data: { targetOptions: {},  }
*/

const initialDownloadFormState = {
  downloadFormData: {},
};

export const DownloadModelFormData = (state = initialDownloadFormState, action) => {
  const { type, payload } = action;
  switch (type) {
    case STORE_DOWNLOAD_MODEL_FORM_DATA:
      return { ...state, downloadFormData: { ...state.downloadFormData, ...payload } };
    default:
      return state;
  }
};

export default DownloadModelFormData;
