export const LOADING_QUERIES = "LOADING_QUERIES";
export const STORE_QUERIES = "STORE_QUERIES";

export const LOADING_QUERY = "LOADING_QUERY";
export const STORE_QUERY = "STORE_QUERY";

export const LOADING_QUERY_CACHE_STATUS = "LOADING_QUERY_CACHE_STATUS";
export const STORE_QUERY_CACHE_STATUS = "STORE_QUERY_CACHE_STATUS";
export const CLEAR_QUERY_CACHE_STATUS = "CLEAR_QUERY_CACHE_STATUS";

export const STORE_SELECTED_QUERY = "STORE_SELECTED_QUERY";
export const ADD_UPDATE_QUERY = "ADD_UPDATE_QUERY";
export const UNCAUGHT_ERROR_QUERY_DATA = "UNCAUGHT_ERROR_QUERY_DATA";
export const UNCAUGHT_ERROR_QUERY_LIST = "UNCAUGHT_ERROR_QUERY_LIST";
export const DELETING_QUERY = "DELETING_QUERY";
export const QUERY_DELETED = "QUERY_DELETED";
export const RESET_QUERY_FEATURE_STATS = "RESET_QUERY_FEATURE_STATS";
export const LOADING_QUERY_FEATURE_STATS = "LOADING_QUERY_FEATURE_STATS";
export const STORE_QUERY_FEATURE_STATS = "STORE_QUERY_FEATURE_STATS";
export const UNCAUGHT_ERROR_QUERY_FEATURE_STATS = "UNCAUGHT_ERROR_QUERY_FEATURE_STATS";

export const BUILD_QUERY_CACHE = "BUILD_QUERY_CACHE";
