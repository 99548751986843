import { LOG_IN, LOAD_TEAM_INFO } from "./actions/actionTypes";

const initialLoggedInState = { loggedIn: false, userId: undefined };
export const loggedIn = (state = initialLoggedInState, action) => {
  switch (action.type) {
    case LOG_IN:
      return {
        loggedIn: true,
        userId: action.userId,
        teamInfo: action.teamInfo,
      };
    case LOAD_TEAM_INFO:
      return { ...state, teamInfo: action.teamInfo };
    default:
      return state;
  }
};

export default loggedIn;
