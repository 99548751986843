import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from "@mui/material";

const useStyles = () =>
  makeStyles((theme) => ({
    header: {
      fontSize: theme.spacing(4.5),
      textAlign: "center",
      fontWeight: 500,
      lineHeight: theme.spacing(4.5),
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    description: {
      fontSize: theme.spacing(2.5),
      textAlign: "center",
      fontWeight: 500,
      lineHeight: theme.spacing(2.5),
    },
  }))();

const PageHeader = ({ title, description }) => {
  const classes = useStyles();

  return (
    <Box mb={"3.5rem"}>
      <Typography className={classes.header} variant={"h2"}>
        {title}
      </Typography>
      <Typography className={classes.description} variant={"subtitle1"} color={"text.secondary"}>
        {description}
      </Typography>
    </Box>
  );
};

export default PageHeader;
