import React from "react";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import { useTheme } from "@mui/material/styles";

import { STATUSES } from "consts";

const StatusIcon = (status) => {
  const theme = useTheme();
  switch (status) {
    case STATUSES.DEBUG:
      return <CodeOutlinedIcon style={{ color: theme.colorConsoleBody }} fontSize="small" />;
    case STATUSES.INFO:
      return <CheckOutlinedIcon style={{ color: theme.colorConsoleInfoIcon }} fontSize="small" />;
    case STATUSES.SUCCESS:
      return <DoneAllIcon style={{ color: theme.colorConsoleSuccessIcon }} fontSize="small" />;
    case STATUSES.WARNING:
      return <ErrorOutlineIcon style={{ color: theme.colorConsoleWarningIcon }} fontSize="small" />;
    case STATUSES.ERROR:
      return <ErrorOutlineIcon style={{ color: theme.colorConsoleErrorIcon }} fontSize="small" />;
    default:
      return <></>;
  }
};

export default StatusIcon;
