import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import useStyles from "./ConsoleViewStyle";

const ConsoleInformationBox = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(1),
  background: "#2d2d2d",
  justifyContent: "flex-end",
  borderBottom: "1px solid #585858",
}));

const ConsoleInformation = ({ consoleInfoChild, consoleActionChild }) => {
  const classes = useStyles();

  return (
    <ConsoleInformationBox>
      <Box className={classes.consoleInfoFlex}>{consoleInfoChild}</Box>
      <Box className={classes.consoleActionFlex}>{consoleActionChild}</Box>
    </ConsoleInformationBox>
  );
};

export default ConsoleInformation;
