import api from "../api";
import logger from "../logger";
import helper from "../helper";
import { STORE_FEATURE_FILES, LOADING_FEATURE_FILES } from "./actionTypes";

export const loadFeatureFiles = (projectId) => async (dispatch) => {
  dispatch({ type: LOADING_FEATURE_FILES });
  let featurefiles = [];
  if (!helper.isNullOrEmpty(projectId)) {
    try {
      const { data: responseBody } = await api.get(`/project/${projectId}/featurefile/`);
      featurefiles = responseBody;
    } catch (err) {
      logger.logError(
        "",
        `${helper.getResponseErrorDetails(err)} \n--projectId:${projectId}`,
        err,
        "loadFeatureFiles",
      );
    }
  }
  dispatch({
    type: STORE_FEATURE_FILES,
    featurefiles,
  });
};
