import { combineReducers } from "redux";
import { SEGMENTS_STORE } from "./actionTypes";

const initialSegments = { data: [], isFetching: false };

export const segments = (state = initialSegments, action) => {
  switch (action.type) {
    case SEGMENTS_STORE:
      return { data: action.payload, isFetching: false };
    default:
      return state;
  }
};

export default combineReducers({
  segments,
});
