import { connect } from "react-redux";

import { loadAccountApiKeys, createAccountApiKey, deleteApiKey } from "store/team/actions";
import TheAccountAPIKeys from "./TheAccountAPIKeys";

const mapStateToProps = (state) => {
  return {
    accountAPIKeys: state?.team?.accountApiKeys?.data || [],
    accountAPIKeysIsLoading: state?.team?.accountApiKeys?.isFetching || false,
  };
};

const mapDispatchToProps = { loadAccountApiKeys, createAccountApiKey, deleteApiKey };

export default connect(mapStateToProps, mapDispatchToProps)(TheAccountAPIKeys);
