import {
  STORE_KNOWLEDGEPACK_PROFILE,
  LOADING_KNOWLEDGEPACK_PROFILE,
  CLEAR_KNOWLEDGEPACK_PROFILE,
} from "./actionTypes";

const initialDeviceProfileState = { data: {}, isFetching: false };
export const deviceProfiles = (state = initialDeviceProfileState, action) => {
  switch (action.type) {
    case LOADING_KNOWLEDGEPACK_PROFILE:
      return { data: {}, isFetching: true };
    case STORE_KNOWLEDGEPACK_PROFILE:
      return { data: action.deviceProfileInfo, isFetching: false };
    case CLEAR_KNOWLEDGEPACK_PROFILE:
      return initialDeviceProfileState;
    default:
      return state;
  }
};

export default deviceProfiles;
