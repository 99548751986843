import { STORE_TRANSFORMS, LOADING_TRANSFORMS } from "./actionTypes";

const initialTransformState = { data: [], isFetching: false };
export function transforms(state = initialTransformState, action) {
  switch (action.type) {
    case LOADING_TRANSFORMS:
      return { data: [], isFetching: true };
    case STORE_TRANSFORMS:
      return { data: action.transforms, isFetching: false };
    default:
      return state;
  }
}

export default transforms;
