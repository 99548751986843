import api from "store/api";
import logger from "store/logger";
import helper from "store/helper";
import { setShowBannersAfterLogin } from "store/common/actions";
import { LOAD_TEAM_INFO } from "./actionTypes";

export const loadTeamSubscription = () => async (dispatch) => {
  try {
    const { data: teamInfo } = await api.get("/team-subscription/");
    dispatch({ type: LOAD_TEAM_INFO, teamInfo });
    dispatch(setShowBannersAfterLogin(teamInfo));
  } catch (err) {
    logger.logError("", helper.getResponseErrorDetails(err), err, "loadTeamSubscription");
  }
};

export default loadTeamSubscription;
