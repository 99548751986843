import { useState } from "react";

import { RESPONSIVE } from "consts";

import useWindowResize from "./useWindowResize";

const useIsShortText = () => {
  const [isShortText, setIsShortText] = useState(false);

  useWindowResize((data) => {
    setIsShortText(data.innerWidth < RESPONSIVE.WIDTH_FOR_SHORT_TEXT);
  });

  return isShortText;
};

export default useIsShortText;
