import makeStyles from "@mui/styles/makeStyles";

const useStyles = () =>
  makeStyles(() => ({
    bannerWrapper: {
      position: "relative",
      background: "#adffbed4",
      fontWeight: "500",
      color: "#384c42",
      padding: "0.5rem",
      display: "flex",
      justifyContent: "center",
      margin: "0 -1rem",
      border: "1px solid #92c59e",
      marginTop: "-1rem",
      marginBottom: "1rem",
    },
    bannerWrapperInfo: {
      position: "relative",
      background: "#cce7ff",
      fontWeight: "500",
      color: "#03446c",
      padding: "0.5rem",
      display: "flex",
      justifyContent: "center",
      border: "1px solid #0277be",
    },
    bannerWrapperInfoAfterLogin: {
      margin: "0 -1rem",
      marginTop: "-1rem",
      marginBottom: "1rem",
      paddingRight: "2rem",
    },
    bannerLink: {
      marginLeft: "0.5rem",
    },
    bannerClose: {
      cursor: "pointer",
      position: "absolute",
      fontWeight: "bold",
      right: "0.5rem",
      top: "15%",
    },
  }))();

export default useStyles;
