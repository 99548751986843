import { STORE_METADATA, LOADING_METADATA } from "./actionTypes";

const initialMetadataState = { data: [], isFetching: false };
export function metadata(state = initialMetadataState, action) {
  switch (action.type) {
    case LOADING_METADATA:
      return { data: [], isFetching: true };
    case STORE_METADATA:
      return { data: action.metadata, isFetching: false };
    default:
      return state;
  }
}

export default metadata;
