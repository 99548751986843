import { connect } from "react-redux";
import TheAccountSettings from "./TheAccountSettings";

const mapDispatchToProps = {};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TheAccountSettings);
