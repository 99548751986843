export const STORE_PIPELINE_STEPS = "STORE_PIPELINE_STEPS";
export const CLEAR_PIPELINE_STEPS = "CLEAR_PIPELINE_STEPS";
export const STORE_PIPELINE_JSON = "STORE_PIPELINE_JSON";
export const CLEAR_PIPELINE_JSON = "CLEAR_PIPELINE_JSON";

export const STORE_ALERT_BUILDER = "STORE_ALERT_BUILDER";
export const CLEAR_ALERT_BUILDER = "CLEAR_ALERT_BUILDER";

export const STORE_PIPELINE_VALIDATION_ERROR = "STORE_PIPELINE_VALIDATION_ERROR";
export const CLEAR_PIPELINE_VALIDATION_ERROR = "CLEAR_PIPELINE_VALIDATION_ERROR";

export const IS_OPTIMIZER_RUNNING = "IS_OPTIMIZER_RUNNING";
export const UPDATE_OPTIMIATION_LOGS = "UPDATE_OPTIMIATION_LOGS";
export const CLEAR_OPTIMIATION_LOGS = "CLEAR_OPTIMIATION_LOGS";

export const SET_IS_SELECT_SCREEN_GRID_VIEW = "SET_IS_SELECT_SCREEN_GRID_VIEW";

export const SET_LOADING_PIPELINE_STEPS = "SET_LOADING_PIPELINE_STEPS";
