import api from "../api";
import logger from "../logger";
import helper from "../helper";
import { STORE_SOURCES, LOADING_SOURCES } from "./actionTypes";

export const loadSources = (projectId) => async (dispatch) => {
  dispatch({ type: LOADING_SOURCES });
  let sourceValues = [];
  if (!helper.isNullOrEmpty(projectId)) {
    try {
      const { data: responseBody } = await api.get(`/project/${projectId}/`);

      if (responseBody && responseBody.capture_sample_schema) {
        sourceValues = Object.keys(responseBody.capture_sample_schema);
      }
    } catch (err) {
      logger.logError(
        "",
        `${helper.getResponseErrorDetails(err)}\n--projectId:${projectId}`,
        err,
        "loadSources",
      );
    }
  }
  dispatch({ type: STORE_SOURCES, sources: sourceValues });
};
