import React from "react";
import { Tabs } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = () =>
  makeStyles((theme) => ({
    ...theme.common,
    root: {
      background: theme.backgroundConsole,
      padding: theme.spacing(1),
      paddingBottom: 0,
      minHeight: theme.spacing(2),
    },
    indicator: {
      display: "none",
      borderBottom: "none",
      backgroundColor: "transparent",
    },
  }))();

const ConsoleTabs = ({ ...props }) => {
  const classes = useStyles();

  return (
    <Tabs
      classes={{ root: classes.root, indicator: classes.indicator }}
      TabIndicatorProps={{ children: <span className={classes.indicator} /> }}
      {...props}
    />
  );
};

export default ConsoleTabs;
