/* eslint-disable max-len */
import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const useStyles = () =>
  makeStyles((theme) => ({
    ...theme.common,
  }))();

const IconSpinneAutoRenew = ({ ...props }) => {
  const classes = useStyles();

  return <AutorenewIcon {...props} className={classes.spinnedIcon} />;
};

export default IconSpinneAutoRenew;
