import _ from "lodash";

export const getFirstPathPart = (pathname) => {
  if (pathname) {
    const pathArr = pathname.split("/");
    if (pathArr?.length >= 1) {
      return pathArr[1];
    }
    return pathname;
  }
  return pathname;
};

export const getCutLastPathPart = (pathname) => {
  if (pathname) {
    const pathArr = pathname.split("/").filter((el) => el);
    return _.join(_.slice(pathArr, 0, -1), "/");
  }
  return pathname;
};
