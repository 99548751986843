import _ from "lodash";
import { useMemo } from "react";

import { useLocation, useHistory } from "react-router-dom";

const useRouterSearchParams = () => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const removeSearchParams = (params) => {
    /**
     * @param {string[]} params
     */
    if (searchParams?.has && _.isArray(params)) {
      params.forEach((param) => {
        if (searchParams.has(param)) {
          searchParams.delete(param);
        }
      });
      history.replace({
        search: searchParams.toString(),
      });
    }
  };

  return [searchParams, removeSearchParams];
};

export default useRouterSearchParams;
