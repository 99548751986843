import {
  STORE_SESSIONS,
  LOADING_SESSIONS,
  SET_SELECTED_SESSION,
  CLEAR_SELECTED_SESSION,
} from "./actionTypes";

const initialSessionsState = { data: [], isFetching: false, selectedSessionUUID: "" };

export const sessions = (state = initialSessionsState, action) => {
  switch (action.type) {
    case LOADING_SESSIONS:
      return { ...state, isFetching: true };
    case STORE_SESSIONS:
      return { ...state, data: action.sessions };
    case SET_SELECTED_SESSION:
      return { ...state, selectedSessionUUID: action.selectedSessionUUID };
    case CLEAR_SELECTED_SESSION:
      return { ...state, selectedSessionUUID: "" };
    default:
      return state;
  }
};

export default sessions;
