import { LOADING_PIPELINE_HIERACHY_RULES, STORE_PIPELINE_HIERACHY_RULES } from "../actionTypes";

const initialPipelineHierarchyRules = { data: [], isFetching: false };
export const pipelineHierarchyRules = (state = initialPipelineHierarchyRules, action) => {
  switch (action.type) {
    case LOADING_PIPELINE_HIERACHY_RULES:
      return { data: [], isFetching: true };
    case STORE_PIPELINE_HIERACHY_RULES:
      return { data: action.payload, isFetching: false };
    default:
      return state;
  }
};

export default pipelineHierarchyRules;
