import React from "react";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTheme } from "@mui/material/styles";

import { RUNNING_STATUSES } from "consts";

import { CircularProgress } from "@mui/material";

const StatusRunningIcon = ({ status }) => {
  const theme = useTheme();

  switch (status) {
    case RUNNING_STATUSES.RUNNING:
      return <CircularProgress style={{ color: theme.colorConsoleInfoIcon }} size="1rem" />;
    case RUNNING_STATUSES.KILLING:
      return <CircularProgress style={{ color: theme.colorConsoleWarningIcon }} size="1rem" />;
    case RUNNING_STATUSES.KILLING_ABORTED:
      return <ErrorOutlineIcon style={{ color: theme.colorConsoleWarningIcon }} fontSize="small" />;
    case RUNNING_STATUSES.KILLED:
      return <DoneAllIcon style={{ color: theme.colorConsoleWarningIcon }} fontSize="small" />;
    case RUNNING_STATUSES.FAILED:
      return <ErrorOutlineIcon style={{ color: theme.colorConsoleErrorIcon }} fontSize="small" />;
    case RUNNING_STATUSES.COMPLETED:
      return <DoneAllIcon style={{ color: theme.colorConsoleSuccessIcon }} fontSize="small" />;
    default:
      return <></>;
  }
};

export default StatusRunningIcon;
