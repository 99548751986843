import { STORE_PLOTS, STORE_PLOT } from "./actionTypes";

export const loadPlots = () => {
  return {
    type: STORE_PLOTS,
    plots: [
      { id: "segment", name: "Segment" },
      { id: "samples", name: "Samples" },
    ],
  };
};

export const setPlot = (plotId) => async (dispatch) => {
  dispatch({
    type: STORE_PLOT,
    plotId,
  });
};
