/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React from "react";
import logger from "store/logger";
import { Card, CardContent, Typography } from "@mui/material";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    logger.logError("", error, errorInfo.componentStack);
  }

  render() {
    if (this.state.errorInfo) {
      // You can render any custom fallback UI
      //   return <h1>Something went wrong.</h1>;
      return (
        <Card style={{ background: "rgb(247, 165, 63)", margin: "15px" }}>
          <CardContent>
            <Typography>
              ⚠️Oops! There was an error.. Our team has been notified with the details..
            </Typography>
            {/* <details style={{ whiteSpace: "pre-wrap" }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details> */}
          </CardContent>
        </Card>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
