import { LOAD_CAPTURES_LABELS, STORE_CAPTURES_LABELS } from "./actionTypes";

const initialCaptureLabelsState = { data: [], isFetching: false };
export const captureSensorLabels = (state = initialCaptureLabelsState, action) => {
  switch (action.type) {
    case LOAD_CAPTURES_LABELS:
      return { data: [], isFetching: true };
    case STORE_CAPTURES_LABELS:
      return { data: action.payload, isFetching: false };
    default:
      return state;
  }
};

export default captureSensorLabels;
