import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(4),
    minHeight: "auto",
    minWidth: "500px",
    position: "relative",
    "@media (max-width: 1300px)": {
      minWidth: "auto",
    },
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    right: theme.spacing(4),
    fontSize: theme.spacing(4),
  },
  typography: {
    fontSize: theme.spacing(2),
    lineHeight: 1.5,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid #dadde9",
    maxWidth: "none",
    boxShadow: theme.shadows[5],
    margin: theme.spacing(1),
  },
}));

export default useStyles;
