import api from "store/api";
import logger from "store/logger";
import helper from "store/helper";

import { STORE_SELECTED_PROJECT } from "../actionTypes";

const loadProjectSummaryData = (projectUUID) => async (dispatch) => {
  try {
    const { data } = await api.get(`/project-summary/${projectUUID}/`);
    dispatch({
      type: STORE_SELECTED_PROJECT,
      selectedProject: data || {},
    });
  } catch (err) {
    logger.logError("", helper.getResponseErrorDetails(err), err, "loadSummaryProjects");
  }
};

export default loadProjectSummaryData;
