import { STORE_SOURCES, LOADING_SOURCES } from "./actionTypes";

const initialSourcesState = { data: [], isFetching: false };
export function sources(state = initialSourcesState, action) {
  switch (action.type) {
    case LOADING_SOURCES:
      return { data: [], isFetching: true };
    case STORE_SOURCES:
      return { data: action.sources, isFetching: false };
    default:
      return state;
  }
}

export default sources;
