import { connect } from "react-redux";
import {
  setIsShowBannerOffer,
  setIsMainScreenLoading,
  setIsShowBannerMaintenance,
} from "store/common/actions";
import Main from "./Main";

const mapStateToProps = (state) => {
  return {
    isMainScreenLoading: state.common?.loaders?.isMainScreenLoading,
    isShowBannerMaintenance: state.common?.banners?.isShowBannerMaintenance || false,
  };
};

const mapDispatchToProps = {
  setIsShowBannerOffer,
  setIsMainScreenLoading,
  setIsShowBannerMaintenance,
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
