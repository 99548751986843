/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { combineReducers } from "redux";
import { SET_LOADING_PIPELINE_TEMPLATES, SET_PIPELINE_TEMPLATES } from "./actionTypes";

export const templates = (state = { data: [], isFetching: false }, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING_PIPELINE_TEMPLATES:
      return { data: [], isFetching: true };
    case SET_PIPELINE_TEMPLATES:
      return { data: payload, isFetching: false };
    default:
      return state;
  }
};

export default combineReducers({ templates });
