import { connect } from "react-redux";
import { logIn, logInOauthCallback, loadTeamInfo } from "store/auth/actions";
import { loadPlatforms } from "store/platforms/actions";
import { loadPipelineSeeds } from "store/pipelines/actions";
import { loadProjects } from "store/projects/actions";
import { loadTransforms } from "store/transforms/actions";

import Auth from "./Auth";

const mapDispatchToProps = {
  logIn,
  logInOauthCallback,
  loadProjects,
  loadTeamInfo,
  loadPlatforms,
  loadPipelineSeeds,
  loadTransforms,
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
