/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { combineReducers } from "redux";
import { SET_PLATFORM_LOGOS, SET_LOADING_PLATFORM_LOGOS } from "./actionTypes";

export const platformLogos = (state = { data: [], isFetching: false }, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING_PLATFORM_LOGOS:
      return { data: {}, isFetching: true };
    case SET_PLATFORM_LOGOS:
      return { data: payload, isFetching: false };
    default:
      return state;
  }
};

export default combineReducers({ platformLogos });
