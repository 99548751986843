import { STORE_FEATURE_FILES, LOADING_FEATURE_FILES } from "./actionTypes";

const initialFeatureFilesState = { data: [], isFetching: false };
export const featurefiles = (state = initialFeatureFilesState, action) => {
  switch (action.type) {
    case LOADING_FEATURE_FILES:
      return { data: [], isFetching: true };
    case STORE_FEATURE_FILES:
      return { data: action.featurefiles, isFetching: false };
    default:
      return state;
  }
};

export default featurefiles;
