import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  createDialogTitle: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  dialogWrapper: {
    padding: theme.spacing(4),
    maxHeight: "60vh",
    position: "relative",
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    fontSize: theme.spacing(4),
  },
  typography: {
    fontSize: theme.spacing(2),
    lineHeight: 1.5,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid #dadde9",
    maxWidth: "none",
    boxShadow: theme.shadows[5],
    margin: theme.spacing(1),
  },
  actionWrapper: {
    padding: "2rem",
  },
}));

export default useStyles;
