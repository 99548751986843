import React, { useEffect } from "react";
import AlertDialog from "components/AlertDialog";

const UnSavedChangesDialog = (props) => {
  const { openDialog, buttonOneAction, buttonTwoAction } = props;
  const [open, setOpen] = React.useState(openDialog);

  const handleButtonOneAction = () => {
    buttonOneAction();
    setOpen(false);
  };

  const handleButtonTwoAction = () => {
    buttonTwoAction();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  return (
    <AlertDialog
      disableEscapeKeyDown
      openDialog={open}
      alertTitle="Unsaved Changes"
      // eslint-disable-next-line max-len
      dialogText="There are unsaved changes, If you leave before saving, your changes will be lost. Do you want to continue?"
      buttonOneText="Yes, Continue"
      buttonTwoText="Back"
      buttonOneAction={handleButtonOneAction}
      buttonTwoAction={handleButtonTwoAction}
    />
  );
};

export default UnSavedChangesDialog;
