import _ from "lodash";

class BaseAPIError extends Error {
  constructor(errorCode, response) {
    super();
    if (errorCode === 404) {
      // eslint-disable-next-line max-len
      this.message = `Server doesn't support API endpoint ${response?.config?.url}. Please, contact support.`;
    } else if (errorCode !== 500) {
      this.message = _.truncate(_.trim(this.parseErrorData(response?.data)), { length: 300 });
    } else if (!response) {
      this.message = "Something went wrong. Please, contact support.";
    } else if (response && _.isString(response)) {
      this.message = response;
    } else {
      this.message = "Something went wrong. Please, contact support.";
    }
    this.name = this.constructor.name;
    this.errorCode = errorCode;
  }

  // eslint-disable-next-line class-methods-use-this
  parseErrorData(data) {
    let message = "";

    const concatMessage = (existingMessage, messageToJoin) => {
      return existingMessage ? `${existingMessage} ${messageToJoin}` : `${messageToJoin}`;
    };

    if (_.isArray(data?.data)) {
      data.data.forEach((value) => {
        if (_.isObject(value)) {
          _.entries(value).forEach(([dataKey, dataValue]) => {
            if (_.isArray(dataValue)) {
              message = concatMessage(message, `${dataKey}: `);
              dataValue.forEach((val) => {
                message = concatMessage(message, val);
              });
            } else {
              message = concatMessage(message, `${dataKey}: ${dataValue}`);
            }
          });
        } else {
          message = concatMessage(message, value);
        }
      });
    } else if (!_.isEmpty(data?.data)) {
      _.entries(data?.data).forEach(([dataKey, dataValue]) => {
        if (_.isArray(dataValue)) {
          message = concatMessage(message, `${dataKey}: `);
          dataValue.forEach((value) => {
            message = concatMessage(message, value);
          });
        } else {
          message = concatMessage(message, `${dataKey}: ${dataValue}`);
        }
      });
    }
    // in these cases details should be at the beginning
    if (data?.message) {
      return concatMessage(data.message, `${message ? "\n" : ""}${message}`);
    }
    if (data?.detail) {
      return concatMessage(data.detail, `${message ? "\n" : ""}${message}`);
    }
    if (data?.error_description) {
      return concatMessage(data.error_description, `${message ? "\n" : ""}${message}`);
    }

    if (!_.isEmpty(data)) {
      _.entries(data).forEach(([dataKey, dataValue]) => {
        if (_.isArray(dataValue)) {
          message = concatMessage(message, `${dataKey}: `);
          dataValue.forEach((value) => {
            message = concatMessage(message, value);
          });
        } else {
          message = concatMessage(message, `${dataKey}: ${dataValue}`);
        }
      });
    }
    return message;
  }
}

export default BaseAPIError;
