import _ from "lodash";
import api from "../api";
import helper from "../helper";
import logger from "../logger";

import { STORE_PLATFORMS, LOADING_PLATFORMS } from "./actionTypes";

const ARMGCC_UUID = "e9f53dfa-f434-4f24-9577-839c190f74da";

export const loadPlatforms = () => async (dispatch) => {
  dispatch({ type: LOADING_PLATFORMS });
  let platforms = [];
  try {
    const { data: responseBody } = await api.get("/platforms/v2");
    platforms = _.sortBy(responseBody, [(o) => _.capitalize(o.name)]);

    const armgcc = platforms[_.findIndex(platforms, (n) => n.uuid === ARMGCC_UUID)];

    platforms = _.remove(platforms, (n) => n.uuid !== ARMGCC_UUID);

    platforms = _.concat(armgcc, platforms);
  } catch (err) {
    logger.logError("", helper.getResponseErrorDetails(err), err, "loadPlatforms");
  }
  dispatch({
    type: STORE_PLATFORMS,
    platforms,
  });
};
