import BaseAPIError from "./api.error.base";

const parseApiError = (apiError, requestName) => {
  if (apiError.response) {
    return new BaseAPIError(apiError.response?.status, apiError.response);
  }
  return new BaseAPIError(503, `Could not send ${`${requestName} `}request`);
};

export default parseApiError;
