import React, { forwardRef } from "react";
import { Tab } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = () =>
  makeStyles((theme) => ({
    ...theme.common,
    root: {
      textTransform: "none",
      color: "white",
      minWidth: theme.spacing(20),
      minHeight: theme.spacing(2),
      fontWeight: theme.typography.fontWeightLight,
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      position: "relative",

      display: "inline-flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",

      "&:hover": {
        opacity: 1,
      },
      "&:selected": {
        background: "#2d2d2d",
        fontWeight: theme.typography.fontWeightRegular,
      },
      "&:focus": {
        color: "",
      },
    },
    selected: {
      background: "#2d2d2d",
    },
  }))();

const ConsoleTab = forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <Tab
      disableRipple
      classes={{ root: classes.root, selected: classes.selected }}
      {...props}
      ref={ref}
    />
  );
});

export default ConsoleTab;
