import { STORE_KNOWLEDGE_PACKS, LOADING_KNOWLEDGE_PACKS } from "./actionTypes";

const initialKnowledgepacksState = { data: [], isFetching: false };
export const knowledgepacks = (state = initialKnowledgepacksState, action) => {
  switch (action.type) {
    case LOADING_KNOWLEDGE_PACKS:
      return { data: [], isFetching: true };
    case STORE_KNOWLEDGE_PACKS:
      return { data: action.knowledgepacks, isFetching: false };
    default:
      return state;
  }
};

export default knowledgepacks;
