import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Tab from "@mui/material/Tab";

import { Paper, Tabs } from "@mui/material/";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
}));

function Footer() {
  const [value, setValue] = React.useState(0);

  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Item One" />
          <Tab label="Item Two" />
          <Tab label="Item Three" />
        </Tabs>
      </Paper>
    </div>
  );
}

export default Footer;
