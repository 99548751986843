import React from "react";

import { Snackbar } from "@mui/material";
import ToastMessage from "components/ToastMessage/ToastMessage";

const UISnackBar = ({ isOpen, onClose, variant, message, autoHideDuration = 3000 }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <ToastMessage onClose={onClose} variant={variant} message={message} />
    </Snackbar>
  );
};

export default UISnackBar;
