import _ from "lodash";

export { default as getChartIterationMetrics } from "./getChartIterationMetrics";
export { default as getPipelinesWithStats } from "./getPipelinesWithStats";

export const getSelectedPipeline = (state) => {
  return state.pipelines?.selectedPipeline;
};

export const getPipelineObj = (pipelineUUID) => (state) => {
  if (state.pipelines?.pipelineList?.data?.length) {
    return state.pipelines?.pipelineList?.data?.find((ppl) => ppl.uuid === pipelineUUID);
  }
  return {};
};

export const getPipelineJSON = (pipelineData) => {
  // eslint-disable-next-line camelcase
  const { pipeline = [], hyper_params = {} } = pipelineData;
  return JSON.stringify({ pipeline, hyper_params });
};

export const getSelectedPipelineObj = (state) => {
  return state.pipelines?.pipelineData?.data || {};
};

export const getPipelineResultTableData = (state) => {
  // convert objet of arrays to array of object
  const pipelineResultData = state.pipelines?.pipelineResults?.data || {};

  if (_.isEmpty(pipelineResultData)) {
    return {};
  }
  const res = [];
  Object.entries(pipelineResultData).forEach(([key, valArr], dataIndex) => {
    if (_.isArray(valArr)) {
      valArr.forEach((value, index) => {
        if (dataIndex === 0) {
          res.push({ [key]: value });
        } else if (res[index]) {
          res[index][key] = value;
        }
      });
    }
  });

  return res;
};
