import { useEffect } from "react";

const useWindowResize = (callback) => {
  /**
   *
   * @returns callback { innerWidth, innerHeight }
   */
  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };

  useEffect(() => {
    const handleWindowResize = () => {
      callback(getWindowSize());
    };

    window.addEventListener("resize", handleWindowResize);

    handleWindowResize();
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
};

export default useWindowResize;
