import i18n from "i18next";

import EnCommon from "./locales/en/common.json";
import EnAccount from "./locales/en/account.json";
import EnComponents from "./locales/en/componets.json";
import EnForms from "./locales/en/forms.json";
import EnLayout from "./locales/en/layout.json";
import EnNavigation from "./locales/en/navigation.json";
import EnModels from "./locales/en/models.json";
import EnProjects from "./locales/en/projects.json";
import EnDataManager from "./locales/en/data-manager.json";
import EnExploreModels from "./locales/en/explore-models.json";
import EnPipelines from "./locales/en/pipelines.json";
import EnQueries from "./locales/en/queries.json";
import EnTeam from "./locales/en/team.json";
import EnAuth from "./locales/en/auth.json";

const loadWithCommon = (loadedResource = {}) => {
  return { ...EnCommon, ...loadedResource };
};

i18n.init({
  resources: {
    en: {
      common: EnCommon,
      components: loadWithCommon(EnComponents),
      account: loadWithCommon(EnAccount),
      forms: EnForms,
      layout: loadWithCommon(EnLayout),
      navigation: EnNavigation,
      models: loadWithCommon(EnModels),
      auth: EnAuth,
      projects: loadWithCommon(EnProjects),
      "data-manager": loadWithCommon(EnDataManager),
      "explore-models": loadWithCommon(EnExploreModels),
      pipelines: loadWithCommon(EnPipelines),
      queries: loadWithCommon(EnQueries),
      team: loadWithCommon(EnTeam),
    },
  },

  fallbackLng: ["en"],
  debug: false,

  // have a common namespace used around the full app
  ns: [
    "common",
    "components",
    "account",
    "forms",
    "layout",
    "navigation",
    "models",
    "auth",
    "project",
    "data-manager",
    "pipelines",
  ],
  defaultNS: "common",

  interpolation: {
    escapeValue: false,
  },

  // in case loading from service like s3 backet
  react: {
    wait: true,
  },

  // keySeparator: false, // we use content as keys
});

export default i18n;
