import { STATUSES, RUNNING_STATUSES } from "consts";

// runnig
export const RUNNING = "RUNNING";
export const KILLING = "KILLING";
export const KILLING_ABORTED = "KILLING_ABORTED";
export const KILLED = "KILLED";
export const COMPLETED = "COMPLETED";
export const FAILED = "FAILED";

export const RUNNING_STATUS = { ...RUNNING_STATUSES };

// mesage

export const MESSAGE_STATUS = { ...STATUSES };

// api
export const API_RUNNING_STATUS = ["PENDING", "STARTED", "SENT"];
export const API_ERROR_STATUS = ["FAILURE"];
export const API_TERMINATTED_STATUS = ["REVOKED"];
