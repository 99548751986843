import makeStyles from "@mui/styles/makeStyles";

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      display: "flex",
      minWidth: theme.responsive.minWidthContainer,
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      boxSizing: "border-box",
      marginTop: theme.spacing(2),
      minHeight: "calc(100vh - 1rem)",
      padding: theme.spacing(2),
      width: "100%",
      background: theme.backgroundApp,
      paddingTop: "4rem",
    },
    infoTitle: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(2),
      fontSize: theme.spacing(4),
      fontWeight: 500,
      textAlign: "center",
    },
  }))();

export default useStyles;
