import { push } from "connected-react-router";

import { ROUTES } from "routers";
import { resetAuthHeader } from "store/api";

import { LOG_OUT } from "./actionTypes";

export { logIn, logInOauthCallback } from "./login";
export { default as loadTeamInfo } from "./loadTeamSubscription";

export const resetApp = () => async (dispatch) => {
  resetAuthHeader();
  await dispatch({ type: LOG_OUT });
  push({ pathname: ROUTES.AUTH.child.LOGIN.path });
};

export const logOut = () => async (dispatch) => {
  await dispatch(resetApp());
  push({ pathname: ROUTES.AUTH.child.LOGIN.path });
};
