import helper from "store/helper";

export const getPipelinesWithStats = (state) => {
  const knowledgepacks = state.knowledgepacks?.data || [];
  const pipelinesData = state.pipelines?.pipelineList?.data || [];

  const getkpCount = (pplUUID) => {
    return knowledgepacks.filter((kp) => kp.sandbox_uuid === pplUUID).length;
  };

  const sortedPipelinesData = helper.sortObjects(
    pipelinesData || [{}],
    "last_modified",
    false,
    "dsc",
  );

  return sortedPipelinesData.map((ppl) => {
    return {
      ...ppl,
      kpCount: getkpCount(ppl.uuid),
    };
  });
};

export default getPipelinesWithStats;
