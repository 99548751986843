import thunk from "redux-thunk";
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import rootReducer from "./reducers";

const history = createBrowserHistory();

const persistConfig = {
  key: "sensiml-projects",
  storage,
  blackList: ["captureLabels"],
};

const middleware = [routerMiddleware(history), thunk];

// eslint-disable-next-line no-unused-vars
const enhancers = [];

const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware));

const emptyBase = () => {}; // uses for test envirement

const persistedReducer = persistReducer(persistConfig, rootReducer(history) || emptyBase);

const store = createStore(persistedReducer, composedEnhancers);

const persistor = persistStore(store);

export { store, persistor, history };
