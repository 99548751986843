import React from "react";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Root = styled(Box)(({ theme }) => ({
  background: "#2d2d2d",
  padding: theme.spacing(1),
  // height: theme.spacing(60),
  resize: "vertical",
  // overflow: "auto",
  position: "relative",
}));

export default (props) => <Root {...props} />;
