import React from "react";

import { RUNNING_STATUSES } from "consts";
import { useTheme } from "@mui/material/styles";

const ConsoleRunningStatusText = ({ status, className, children }) => {
  const theme = useTheme();

  const getColor = () => {
    switch (status) {
      case RUNNING_STATUSES.RUNNING:
        return theme.colorConsoleInfoIcon;

      case RUNNING_STATUSES.KILLING:
        return theme.colorConsoleWarningIcon;

      case RUNNING_STATUSES.KILLING_ABORTED:
        return theme.colorConsoleWarningIcon;

      case RUNNING_STATUSES.KILLED:
        return theme.colorConsoleWarningIcon;

      case RUNNING_STATUSES.FAILED:
        return theme.colorConsoleErrorIcon;

      case RUNNING_STATUSES.COMPLETED:
        return theme.colorConsoleSuccessIcon;
      default:
        return theme.colorConsoleSuccessIcon;
    }
  };

  return (
    <span className={className} style={{ color: getColor() }}>
      {children}
    </span>
  );
};

export default ConsoleRunningStatusText;
