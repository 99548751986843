import React from "react";
import PropTypes from "prop-types";

import { Backdrop } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Loader from "react-loader-spinner";
import useStyles from "./UILoadersStyles";

const AppLoader = ({ isOpen, message }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Backdrop
      // transitionDuration={{ appear: 500, enter: 1500, exit: 500 }}
      style={{ zIndex: 100, backgroundColor: "" }}
      className={classes.appBackdrop}
      open={isOpen || false}
    >
      <Loader
        type="Bars"
        color={theme.colorLoader}
        className={classes.apploaderIcon}
        height={100}
        width={100}
      />
      <p className={classes.messageLoader}>{message}</p>
    </Backdrop>
  );
};

AppLoader.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
};

AppLoader.defaultProps = {
  isOpen: false,
  message: "",
};

export default AppLoader;
