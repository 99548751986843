import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./DialogInformationStyles";

const DialogInformation = ({ isOpen, onClose, maxWidth, children }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={maxWidth || "md"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.dialogWrapper}>
        <CloseIcon className={classes.closeIcon} onClick={onClose} />
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default DialogInformation;
