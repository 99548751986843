import React from "react";
import { Tooltip, IconButton } from "@mui/material";

const IconButtonConvertibleToShort = ({ tooltip, isShort, icon, shortIcon, ...btnProps }) => {
  return (
    <span>
      <Tooltip title={tooltip}>
        <span>
          <IconButton {...btnProps}>{isShort ? icon : <>{shortIcon}</>}</IconButton>
        </span>
      </Tooltip>
    </span>
  );
};

export default IconButtonConvertibleToShort;
