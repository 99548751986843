import makeStyles from "@mui/styles/makeStyles";

const useStyles = () =>
  makeStyles((theme) => ({
    apploaderIcon: {
      color: theme.colorLoader,
    },
    messageLoader: {
      color: theme.colorBrandText,
    },
    appBackdrop: {
      backgroundColor: theme.backgroundLoaderBackdrop,
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    elementBackdrop: {
      width: "100%",
      height: "100%",
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: theme.spacing(20),
      backgroundColor: theme.backgroundLoaderElementBackdrop,
      zIndex: 1,
    },
  }))();

export default useStyles;
