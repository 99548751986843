/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";

import AppLoader from "components/UILoaders/AppLoader";
import { useRouterSearchParams } from "hooks";

import { ROUTES } from "routers";

const TheOAuthCallback = ({ logInOauthCallback }) => {
  const [searchParams] = useRouterSearchParams();
  const routersHistory = useHistory();

  const [cookies, _setCookie, removeCookie] = useCookies();

  const redirectWithError = (errorMessage) => {
    routersHistory.push({
      pathname: ROUTES.AUTH.child.LOGIN.path,
      search: `?error=${errorMessage}`,
    });
  };

  useEffect(() => {
    if (searchParams && !searchParams.get("error") && cookies?.email && cookies?.token) {
      try {
        logInOauthCallback({
          email: cookies.email,
          refreshToken: cookies.refresh_token,
          accessToken: cookies.token,
        });
        removeCookie("email", { path: "/" });
        removeCookie("refresh_token", { path: "/" });
        removeCookie("token", { path: "/" });
      } catch (error) {
        redirectWithError(error.message);
      }
    } else if (searchParams && searchParams.get("error")) {
      redirectWithError(searchParams.get("error"));
    } else {
      redirectWithError("Something went wrong during login throw the OAuth service");
    }
  }, [searchParams]);

  return (
    <Box style={{ height: "100vh" }}>
      <AppLoader isOpen message={"Loading profile information ..."} />
    </Box>
  );
};

export default TheOAuthCallback;
