import React from "react";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./BannerStyles";

const BannerMaintenance = ({ text, onClose, isAfterLogin = true }) => {
  const classes = useStyles();
  return (
    <Box
      className={`${isAfterLogin && classes.bannerWrapperInfoAfterLogin} ${
        classes.bannerWrapperInfo
      }`}
    >
      {text}
      {onClose ? <CloseIcon className={classes.bannerClose} onClick={onClose} /> : null}
    </Box>
  );
};

export default BannerMaintenance;
