import { STORE_CAPTURE_CONFIGURATIONS, LOADING_CAPTURE_CONFIGURATIONS } from "./actionTypes";

const initialCaptureConfigurationsState = { data: [], isFetching: false };
export const captureConfigurations = (state = initialCaptureConfigurationsState, action) => {
  switch (action.type) {
    case LOADING_CAPTURE_CONFIGURATIONS:
      return { data: [], isFetching: true };
    case STORE_CAPTURE_CONFIGURATIONS:
      return { data: action.captureConfigurations, isFetching: false };
    default:
      return state;
  }
};

export default captureConfigurations;
